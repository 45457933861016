import {
  Context,
  createContext,
  createRef,
  Dispatch,
  FC,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  ApplicationFieldInput,
  CommunityCommunityAppChoices,
  CommunityCommunityJoinPolicyChoices,
  LandingBenefitsInput,
  LandingCtaFields,
  LandingHeaderInput,
  LandingMemberInput,
  LandingRequirementsFields,
  MembersStatusEnum,
  MutationLandingSetHeaderImageArgs
} from 'generated/types';
import {
  useCommunityInfoQuery,
  useLandingApplicationFieldsQuery,
  useLandingMembersQuery,
  useLandingQuery
} from 'generated/hooks';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { OWNER_ROUTES } from 'utils/routes';

interface ILandingProvider {
  children: ReactNode;
}

export type HeroFormValues = LandingHeaderInput & {
  headerImage: MutationLandingSetHeaderImageArgs['image'];
};

export type MemberType = LandingMemberInput & {
  image?: string | null;
};

export interface IApplicationFields {
  fields: ApplicationFieldInput[];
  hasInviteLink: boolean;
  inviteLink: string;
}

interface ILandingBuilderContext {
  savedHeroValues: HeroFormValues;
  setSavedHeroValues: Dispatch<SetStateAction<HeroFormValues>>;
  /*------------------------------------------------------------------*/
  savedBenefitsValues: LandingBenefitsInput;
  setSavedBenefitsValues: Dispatch<SetStateAction<LandingBenefitsInput>>;
  /*------------------------------------------------------------------*/
  savedMembersValues: { members: MemberType[] };
  setSavedMembersValues: Dispatch<SetStateAction<{ members: MemberType[] }>>;
  /*------------------------------------------------------------------*/
  savedRequirementsValues: LandingRequirementsFields;
  setSavedRequirementsValues: Dispatch<SetStateAction<LandingRequirementsFields>>;
  /*------------------------------------------------------------------*/
  savedCtaValues: LandingCtaFields;
  setSavedCtaValues: Dispatch<SetStateAction<LandingCtaFields>>;
  /*------------------------------------------------------------------*/
  savedApplicationValues: IApplicationFields;
  setSavedApplicationValues: Dispatch<SetStateAction<IApplicationFields>>;
  /*------------------------------------------------------------------*/
  heroRef: RefObject<HTMLFormElement>;
  benefitsRef: RefObject<HTMLFormElement>;
  membersRef: RefObject<HTMLFormElement>;
  requirementsRef: RefObject<HTMLFormElement>;
  ctaRef: RefObject<HTMLFormElement>;
  applicationFieldsRef: RefObject<HTMLFormElement>;
  /*------------------------------------------------------------------*/
  landingFetching: boolean;
  /*------------------------------------------------------------------*/
  savingStatus: string;
  onSetSavingStatus: Dispatch<SetStateAction<string>>;
  /*------------------------------------------------------------------*/
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  /*------------------------------------------------------------------*/
  isOpenIntroModal: boolean;
  setOpenIntroModal: Dispatch<SetStateAction<boolean>>;
  /*------------------------------------------------------------------*/
  updatedSection: Set<string>;
  handleUpdateSection: (section: string) => void;
  /*------------------------------------------------------------------*/
  isNewLanding: boolean;
  /*------------------------------------------------------------------*/
  app: CommunityCommunityAppChoices;
  /*------------------------------------------------------------------*/
  heroFormValues: HeroFormValues;
  setHeroFormValues: Dispatch<SetStateAction<HeroFormValues>>;
  /*------------------------------------------------------------------*/
  benefitsFormValues: LandingBenefitsInput;
  setBenefitsFormValues: Dispatch<SetStateAction<LandingBenefitsInput>>;
  /*------------------------------------------------------------------*/
  membersFormValues: { members: MemberType[] };
  setMembersFormValues: Dispatch<SetStateAction<{ members: MemberType[] }>>;
  /*------------------------------------------------------------------*/
  requirementsFormValues: LandingRequirementsFields;
  setRequirementsFormValues: Dispatch<SetStateAction<LandingRequirementsFields>>;
  /*------------------------------------------------------------------*/
  ctaFormValues: LandingCtaFields;
  setCtaFormValues: Dispatch<SetStateAction<LandingCtaFields>>;
  /*------------------------------------------------------------------*/
  applicationFormValues: IApplicationFields;
  setApplicationFormValues: Dispatch<SetStateAction<IApplicationFields>>;
  /*------------------------------------------------------------------*/
  hasInviteLink: boolean;
  inviteLink: string;
  /*------------------------------------------------------------------*/
  isLandingIsInProgress: boolean;
  isMembersFetching: boolean;
  isLandingFetching: boolean;
  onCancelMembersGeneration: () => void;
  /*------------------------------------------------------------------*/
}

const defaultValues: ILandingBuilderContext = {
  savedHeroValues: {} as HeroFormValues,
  setSavedHeroValues: () => void 0,
  /*------------------------------------------------------------------*/
  savedBenefitsValues: {} as LandingBenefitsInput,
  setSavedBenefitsValues: () => void 0,
  /*------------------------------------------------------------------*/
  savedMembersValues: { members: [] } as { members: MemberType[] },
  setSavedMembersValues: () => void 0,
  /*------------------------------------------------------------------*/
  savedRequirementsValues: {} as LandingRequirementsFields,
  setSavedRequirementsValues: () => void 0,
  /*------------------------------------------------------------------*/
  savedCtaValues: {} as LandingCtaFields,
  setSavedCtaValues: () => void 0,
  /*------------------------------------------------------------------*/
  savedApplicationValues: {
    hasInviteLink: false,
    inviteLink: '',
    fields: []
  },
  setSavedApplicationValues: () => void 0,
  /*------------------------------------------------------------------*/
  heroRef: createRef<HTMLFormElement>(),
  benefitsRef: createRef<HTMLFormElement>(),
  membersRef: createRef<HTMLFormElement>(),
  requirementsRef: createRef<HTMLFormElement>(),
  ctaRef: createRef<HTMLFormElement>(),
  applicationFieldsRef: createRef<HTMLFormElement>(),
  /*------------------------------------------------------------------*/
  landingFetching: false,
  /*------------------------------------------------------------------*/
  savingStatus: '',
  onSetSavingStatus: () => void 0,
  /*------------------------------------------------------------------*/
  isLoading: false,
  setLoading: () => void 0,
  /*------------------------------------------------------------------*/
  isOpenIntroModal: false,
  setOpenIntroModal: () => void 0,
  /*------------------------------------------------------------------*/
  updatedSection: new Set<string>(),
  handleUpdateSection: (section: string) => void 0,
  /*------------------------------------------------------------------*/
  isNewLanding: false,
  /*------------------------------------------------------------------*/
  app: CommunityCommunityAppChoices.Telegram,
  /*------------------------------------------------------------------*/
  heroFormValues: {} as HeroFormValues,
  setHeroFormValues: () => void 0,
  /*------------------------------------------------------------------*/
  benefitsFormValues: {} as LandingBenefitsInput,
  setBenefitsFormValues: () => void 0,
  /*------------------------------------------------------------------*/
  membersFormValues: { members: [] } as { members: MemberType[] },
  setMembersFormValues: () => void 0,
  /*------------------------------------------------------------------*/
  requirementsFormValues: {} as LandingRequirementsFields,
  setRequirementsFormValues: () => void 0,
  /*------------------------------------------------------------------*/
  ctaFormValues: {} as LandingCtaFields,
  setCtaFormValues: () => void 0,
  /*------------------------------------------------------------------*/
  applicationFormValues: {
    hasInviteLink: false,
    inviteLink: '',
    fields: []
  },
  setApplicationFormValues: () => void 0,
  /*------------------------------------------------------------------*/
  hasInviteLink: false,
  inviteLink: '',
  /*------------------------------------------------------------------*/
  isLandingIsInProgress: false,
  isMembersFetching: false,
  isLandingFetching: false,
  onCancelMembersGeneration: () => void 0,
  /*------------------------------------------------------------------*/
};

export enum SavingStatuses {
  SAVED = 'saved',
  SAVING = 'saving',
}

const LandingBuilderContext: Context<ILandingBuilderContext> = createContext(defaultValues);

const REFRESH_INTERVAL = 3000;

export const LandingBuilderProvider: FC<ILandingProvider> = ({ children }) => {
  const path = usePathname();
  /*------------------------------------------------------------------*/
  const heroRef = useRef<HTMLFormElement>(null);
  const benefitsRef = useRef<HTMLFormElement>(null);
  const membersRef = useRef<HTMLFormElement>(null);
  const requirementsRef = useRef<HTMLFormElement>(null);
  const ctaRef = useRef<HTMLFormElement>(null);
  const applicationFieldsRef = useRef<HTMLFormElement>(null);

  const [response, reFetchLanding] = useLandingQuery();
  const { data: landingData, fetching: landingFetching } = response;

  const [membersResponse, reFetchLandingMembers] = useLandingMembersQuery();
  const { data: landingMembersData, fetching: landingMembersFetching } = membersResponse;

  const [applicationFieldsResponse, reFetchApplicationFields] = useLandingApplicationFieldsQuery();
  const { data: applicationFieldsData, fetching: applicationFetching } = applicationFieldsResponse;

  const [communityResponse] = useCommunityInfoQuery();
  const { data: communityData, fetching: communityInfoFetching } = communityResponse;

  const [isLandingIsInProgress, setIsLandingIsInProgress] = useState<boolean>(true);
  const [isMembersFetching, setIsMembersFetching] = useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (path === OWNER_ROUTES.LANDING_BUILDER.getPath() && !landingData?.landing?.headerTitle) {
      interval = setInterval(() => {
        reFetchLanding();
        reFetchApplicationFields();
      }, REFRESH_INTERVAL);
    } else {
      setIsLandingIsInProgress(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [landingData?.landing?.headerTitle, landingData?.landing.membersStatus, path, reFetchApplicationFields, reFetchLanding, setIsMembersFetching]);

  const isLandingFetching = useMemo(() => {
    return isLandingIsInProgress || (landingFetching && !isMembersFetching);
  }, [isLandingIsInProgress, isMembersFetching, landingFetching]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (path === OWNER_ROUTES.LANDING_BUILDER.getPath() && !isLandingIsInProgress && (landingData?.landing.membersStatus === MembersStatusEnum.InProgress && landingMembersData?.landing.membersStatus === MembersStatusEnum.InProgress)) {
      setIsMembersFetching(() => true);
      interval = setInterval(() => {
        reFetchLandingMembers();
      }, REFRESH_INTERVAL);
    } else if (path === OWNER_ROUTES.LANDING_BUILDER.getPath() && !isLandingIsInProgress && (landingData?.landing.membersStatus !== MembersStatusEnum.InProgress || landingMembersData?.landing.membersStatus !== MembersStatusEnum.InProgress)) {
      setIsMembersFetching(() => false);
    }

    return () => {
      clearInterval(interval);
    }
  }, [isLandingIsInProgress, isMembersFetching, landingData?.landing.members.length, landingData?.landing.membersStatus, landingMembersData?.landing.membersStatus, path, reFetchLanding, reFetchLandingMembers]);

  useEffect(() => {
    if (landingData?.landing.membersStatus === MembersStatusEnum.Finished) {
      setIsMembersFetching(() => false);
    }
  }, [landingData?.landing.membersStatus]);

  const onCancelMembersGeneration = useCallback(() => {
    reFetchLandingMembers();
  }, [reFetchLandingMembers]);

  /*--------------------------------------------------------------------------------------------------*/

  const [savedHeroValues, setSavedHeroValues] = useState<HeroFormValues>({
    headerTitle: '',
    headerSubtitle: '',
    headerImage: '',
    youtubeVideoUrl: '',
    membersNumber: '',
    headerTags: ''
  });
  const [heroFormValues, setHeroFormValues] = useState<HeroFormValues>({} as HeroFormValues);

  /*--------------------------------------------------------------------------------------------------*/

  const [savedBenefitsValues, setSavedBenefitsValues] = useState<LandingBenefitsInput>({
    title: '',
    description: '',
    item1: '',
    item2: '',
    item3: '',
    item4: '',
    item5: '',
    item6: ''
  });
  const [benefitsFormValues, setBenefitsFormValues] = useState<LandingBenefitsInput>({} as LandingBenefitsInput);

  /*--------------------------------------------------------------------------------------------------*/

  const [savedMembersValues, setSavedMembersValues] = useState<{ members: MemberType[] }>({ members: [] });
  const [membersFormValues, setMembersFormValues] = useState<{ members: MemberType[] }>({ members: [] });

  /*--------------------------------------------------------------------------------------------------*/

  const [savedRequirementsValues, setSavedRequirementsValues] = useState<LandingRequirementsFields>({
    title: '',
    content: ''
  });

  const [requirementsFormValues, setRequirementsFormValues] = useState<LandingRequirementsFields>({} as LandingRequirementsFields);

  /*--------------------------------------------------------------------------------------------------*/

  const [savedCtaValues, setSavedCtaValues] = useState<LandingCtaFields>({
    title: '',
    buttonText: ''
  });

  const [ctaFormValues, setCtaFormValues] = useState<LandingCtaFields>({} as LandingCtaFields);

  /*--------------------------------------------------------------------------------------------------*/

  const [savedApplicationValues, setSavedApplicationValues] = useState<IApplicationFields>({
    fields: [],
    hasInviteLink: false,
    inviteLink: ''
  });

  useEffect(() => {
    if (!applicationFetching && applicationFieldsData?.landing.applicationFields && !communityInfoFetching && communityData?.communityInfo) {
      setSavedApplicationValues(() => ({
        fields: applicationFieldsData?.landing.applicationFields ?? [],
        hasInviteLink: communityData?.communityInfo?.joinPolicy === CommunityCommunityJoinPolicyChoices.AfterApply,
        inviteLink: communityData?.communityInfo?.telegramJoinChatLink ?? ''
      }));
    }
  }, [applicationFetching, applicationFieldsData?.landing.applicationFields, communityData?.communityInfo, communityData?.communityInfo?.joinPolicy, communityData?.communityInfo?.telegramJoinChatLink, communityInfoFetching]);

  const [applicationFormValues, setApplicationFormValues] = useState<IApplicationFields>({
    fields: [],
    hasInviteLink: false,
    inviteLink: ''
  });

  /*--------------------------------------------------------------------------------------------------*/

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!!landingData && !landingData.landing.builderVideoSeen) {
      setOpenModal(true);
    }
  }, [landingData]);

  /*--------------------------------------------------------------------------------------------------*/

  const [isLoading, setLoading] = useState<boolean>(false);

  /*--------------------------------------------------------------------------------------------------*/
  const [savingStatus, setSavingStatus] = useState<string>('');

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (savingStatus === SavingStatuses.SAVED) {
      timer = setTimeout(() => {
        setSavingStatus('');
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [savingStatus]);

  /*--------------------------------------------------------------------------------------------------*/
  const [updatedSection, setUpdatedSection] = useState<Set<string>>(new Set());

  const handleUpdateSection = useCallback((section: string) => {
      setUpdatedSection((prev) => {
        prev.add(section);
        return prev;
      });
    }, []);
  /*--------------------------------------------------------------------------------------------------*/

  const params = useSearchParams();
  const router = useRouter();
  const [isNewLanding, setNewLandingFlag] = useState<boolean>(false);

  useEffect(() => {
    if (params?.get('create_new_landing')) {
      setNewLandingFlag(() => true);
      router.replace(OWNER_ROUTES.LANDING_BUILDER.getPath());
    }
  }, [params, router]);

  /*--------------------------------------------------------------------------------------------------*/

  useEffect(() => {
    if (!landingFetching && landingData?.landing) {

      const {
        __typename,
        benefits: landingBenefits,
        members,
        requirements,
        cta,
        ...hero
      } = landingData?.landing;

      /* Set hero */

      setSavedHeroValues(() => ({
        headerTitle: hero.headerTitle ?? '',
        headerSubtitle: hero.headerSubtitle ?? '',
        headerImage: hero.headerImage ?? '',
        youtubeVideoUrl: hero.youtubeVideoUrl ?? '',
        membersNumber: hero.membersNumber ?? '',
        headerTags: hero.headerTags ?? ''
      }));

      setHeroFormValues(() => ({
        headerTitle: hero.headerTitle ?? '',
        headerSubtitle: hero.headerSubtitle ?? '',
        headerImage: hero.headerImage ?? '',
        youtubeVideoUrl: hero.youtubeVideoUrl ?? '',
        membersNumber: hero.membersNumber ?? '',
        headerTags: hero.headerTags ?? ''
      }));

      /* Set benefits */

      const { __typename: __benefitsTypename, title, description, ...benefits } = landingBenefits;

      setSavedBenefitsValues(() => ({
        title: title ?? '',
        description: description ?? '',
        item1: benefits.item1 ?? '',
        item2: benefits.item2 ?? '',
        item3: benefits.item3 ?? '',
        item4: benefits.item4 ?? '',
        item5: benefits.item5 ?? '',
        item6: benefits.item6 ?? ''
      }));
      setBenefitsFormValues(() => ({
        title: title ?? '',
        description: description ?? '',
        item1: benefits.item1 ?? '',
        item2: benefits.item2 ?? '',
        item3: benefits.item3 ?? '',
        item4: benefits.item4 ?? '',
        item5: benefits.item5 ?? '',
        item6: benefits.item6 ?? ''
      }));

      /* Set members */

      setSavedMembersValues(() => ({ members: members ?? [] }));
      setMembersFormValues(() => ({ members: members ?? [] }));

      /* Set requirements */

      setSavedRequirementsValues(() => ({
        title: requirements.title ?? '',
        content: requirements.content ?? ''
      }));

      setRequirementsFormValues(() => ({
        title: requirements.title ?? '',
        content: requirements.content ?? ''
      }));

      /* Set CTA */

      setSavedCtaValues(() => ({
        title: cta.title ?? '',
        buttonText: cta.buttonText ?? ''
      }));

      setCtaFormValues(() => ({
        title: cta.title ?? '',
        buttonText: cta.buttonText ?? ''
      }));
    }
  }, [landingData?.landing, landingFetching]);

  useEffect(() => {
    if (!landingMembersFetching && landingMembersData?.landing) {
      const { members } = landingMembersData?.landing;

      if (landingMembersData.landing.membersStatus !== MembersStatusEnum.InProgress) {
        setSavedMembersValues(() => ({ members: members ?? [] }));
        setMembersFormValues(() => ({ members: members ?? [] }));
      }
    }
  }, [landingMembersData?.landing, landingMembersFetching]);

  /*--------------------------------------------------------------------------------------------------*/

  const value: ILandingBuilderContext = useMemo(() => ({
    heroFormValues,
    setHeroFormValues,
    savedHeroValues,
    setSavedHeroValues,
    /*------------------------------------------------------------------*/
    benefitsFormValues,
    setBenefitsFormValues,
    savedBenefitsValues,
    setSavedBenefitsValues,
    /*------------------------------------------------------------------*/
    membersFormValues,
    setMembersFormValues,
    savedMembersValues,
    setSavedMembersValues,
    /*------------------------------------------------------------------*/
    requirementsFormValues,
    setRequirementsFormValues,
    savedRequirementsValues,
    setSavedRequirementsValues,
    /*------------------------------------------------------------------*/
    ctaFormValues,
    setCtaFormValues,
    savedCtaValues,
    setSavedCtaValues,
    /*------------------------------------------------------------------*/
    applicationFormValues,
    setApplicationFormValues,
    savedApplicationValues,
    setSavedApplicationValues,
    /*------------------------------------------------------------------*/
    heroRef,
    benefitsRef,
    membersRef,
    requirementsRef,
    ctaRef,
    applicationFieldsRef,
    /*------------------------------------------------------------------*/
    landingFetching,
    /*------------------------------------------------------------------*/
    savingStatus,
    onSetSavingStatus: setSavingStatus,
    /*------------------------------------------------------------------*/
    isLoading,
    setLoading,
    /*------------------------------------------------------------------*/
    isOpenIntroModal: openModal,
    setOpenIntroModal: setOpenModal,
    /*------------------------------------------------------------------*/
    updatedSection,
    handleUpdateSection,
    /*------------------------------------------------------------------*/
    isNewLanding,
    /*------------------------------------------------------------------*/
    app: communityData?.communityInfo?.app as CommunityCommunityAppChoices,
    /*------------------------------------------------------------------*/
    hasInviteLink: communityData?.communityInfo?.joinPolicy === CommunityCommunityJoinPolicyChoices.AfterApply,
    inviteLink: communityData?.communityInfo?.telegramJoinChatLink ?? '',
    /*------------------------------------------------------------------*/
    isLandingIsInProgress,
    isMembersFetching,
    isLandingFetching,
    onCancelMembersGeneration,
    /*------------------------------------------------------------------*/
  }), [heroFormValues, savedHeroValues, benefitsFormValues, savedBenefitsValues, membersFormValues, savedMembersValues, requirementsFormValues, savedRequirementsValues, ctaFormValues, savedCtaValues, applicationFormValues, savedApplicationValues, landingFetching, savingStatus, isLoading, openModal, updatedSection, handleUpdateSection, isNewLanding, communityData?.communityInfo?.app, communityData?.communityInfo?.joinPolicy, communityData?.communityInfo?.telegramJoinChatLink, isLandingIsInProgress, isMembersFetching, isLandingFetching, onCancelMembersGeneration]);

  return (
    <LandingBuilderContext.Provider value={value}>
      {children}
    </LandingBuilderContext.Provider>
  );
};

export default LandingBuilderContext;
